<template>
    <v-app dark>
        <v-container fluid class="pa-0" style="position: fixed; height: 100vh; width: 100vw; overflow: hidden;" :style="'background: rgb(2,0,36); background: linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(28,90,168,1) 50%, rgba(0,212,255,1) 100%);'">
            <v-img
                width="100vw"
                height="100vh"
                src="@/assets/backgrounds/bg01.jpg"
                position="center center"
                gradient="to top right, rgba(225,7,19,.65), rgba(155,35,63,.95)"
            />
        </v-container>
        <v-slide-x-transition leave-absolute>
            <v-main fluid dark v-if="anzeigetafel && anzeigetafel.screen == 'Next'">
                <v-row justify="space-between" class="text-center" style="height: 100vh;">
                    <v-col cols="4" align-self="center">
                        <v-avatar color="white" height="20vw" width="20vw">
                            <v-img :src="anzeigetafel.heim.logo"></v-img>
                        </v-avatar>
                        <h1 class="display-3 mt-4 font-weight-bold">{{ anzeigetafel.heim.name }}</h1>
                    </v-col>
                    <v-col cols="4" align-self="center">
                        <v-row justify="center" class="mb-3">
                            <v-col cols="6">
                                <v-card class="rounded-xl pa-2" color="rgba(222,222,222,0.9)">
                                    <v-img contain src="@/assets/logos/arag_cup_logo_rgb.png"></v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                        <h1 class="display-3 text-uppercase font-weight-bold mb-3 vox-round-bold">{{ anzeigetafel.runde.name }}</h1>
                        <h1 class="display-2 text-uppercase font-weight-bold mb-3" style="color: #fdd416;">{{ anzeigetafel.gruppe.name }}</h1>
                    </v-col>
                    <v-col cols="4" align-self="center">
                        <v-avatar color="white" height="20vw" width="20vw">
                            <v-img :src="anzeigetafel.gast.logo"></v-img>
                        </v-avatar>
                        <h1 class="display-3 mt-4 font-weight-bold">{{ anzeigetafel.gast.name }}</h1>
                    </v-col>
                </v-row>
                <v-row justify="center" style="position: absolute; width: 100vw; top: 50px;">
                    <v-col cols="8" class="text-center">
                        <h1 class="display-4 font-weight-bold mb-5 vox-round-bold">NÄCHSTES SPIEL</h1>
                    </v-col>
                </v-row>
            </v-main>
        </v-slide-x-transition>
        <v-slide-x-transition leave-absolute>
            <v-main fluid dark v-if="anzeigetafel && anzeigetafel.screen == 'Custom'">
                <v-img contain :src="anzeigetafel.customScreen.image.url" style="width: 100vw; height: 100vh;"></v-img>
            </v-main>
            <v-main fluid dark v-if="anzeigetafel && anzeigetafel.screen == 'Torschuetze'">
                <v-row justify="end" style="width: 100vw; position: absolute; top: 2vh; right: 2vw;">
                    <v-col cols="3" align-self="center">
                        <v-card class="rounded-xl pa-1 py-5" :color="turnier.website.theme.secondary.hex" :light="!turnier.website.theme.secondary.isDark()" :dark="turnier.website.theme.primary.isDark()" >
                            <keep-alive>
                                <v-row justify="center" class="text-center">
                                    <v-col cols="12" class="py-0" align-self="center">
                                        <h1 class="display-2 font-weight-bold text-uppercase vox-round-bold-italic">Spielzeit</h1>
                                        <h1 class="display-4 font-weight-bold my-0">{{ timer.minuten.toString().padStart(2, '0') }}:{{ timer.sekunden.toString().padStart(2, '0') }}</h1>
                                    </v-col>        
                                </v-row>
                            </keep-alive>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify="center" class="text-center" style="height: 100vh;" v-if="torschuetze">
                    <v-col cols="12" align-self="center">
                        <v-row justify="center" style="padding-top: 5vh;">
                            <v-col cols="auto" align-self="center">
                                <h1 class="goal-team font-weight-bold"><span class="goal mb-3 font-weight-bold">TOOOR</span> <br />FÜR <span class="yellow--text text-uppercase">{{ torschuetze.team.name }}</span>!!!</h1>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4" align-self="center" v-if="anzeigetafel.heim.name == torschuetze.team.name">
                        <v-avatar color="white" size="20vw">
                            <v-img :src="anzeigetafel.heim.logo"></v-img>
                        </v-avatar>
                    </v-col>
                    <v-col cols="8" align-self="center">
                        <h1 class="torschuetze font-weight-bold">{{ torschuetze.spieler.vorname }} {{ torschuetze.spieler.nachname }}</h1>
                        <h1 class="torschuetze-tore font-weight-bold"><span class="torschuetze yellow--text">{{ torschuetze.spieler.erzielte_tore }}</span>. Turniertor</h1>
                    </v-col>
                    <v-col cols="4" align-self="center" v-if="anzeigetafel.gast.name == torschuetze.team.name">
                        <v-avatar color="white" size="20vw">
                            <v-img :src="anzeigetafel.gast.logo"></v-img>
                        </v-avatar>
                    </v-col>
                </v-row>
            </v-main>
        </v-slide-x-transition>
        <v-slide-x-transition leave-absolute>
            <v-main fluid dark v-if="anzeigetafel && anzeigetafel.screen == 'Tor_Heim'">
                <v-row justify="end" style="width: 100vw; position: absolute; top: 3vh; right: 2vw;">
                    <v-col cols="3" align-self="center">
                        <v-card class="rounded-xl pa-0" color="rgba(253,212,22,0.9)" light>
                            <keep-alive>
                                <v-row justify="center" class="text-center">
                                    <v-col cols="8" class="py-0" align-self="center">
                                        <h1 class="display-1 font-weight-bold text-uppercase vox-round-bold">Spielzeit</h1>
                                        <h1 class="display-4 font-weight-bold my-0">{{ timer.minuten.toString().padStart(2, '0') }}:{{ timer.sekunden.toString().padStart(2, '0') }}</h1>
                                    </v-col>  
                                    <v-col cols="4" align-self="center" class="pa-0">
                                        <v-card class="rounded-r-xl rounded-l-0" color="rgba(222,222,222,0.95)">
                                            <v-img contain src="@/assets/logos/arag_cup_logo_rgb.png"></v-img>
                                        </v-card>
                                    </v-col>      
                                </v-row>
                            </keep-alive>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify="center" class="text-center" style="height: 100vh;">
                    <v-col cols="12" align-self="center">
                        <v-row justify="center" style="padding-top: 5vh;">
                            <v-col cols="auto" align-self="center">
                                <h1 class="display-3 font-weight-bold"><span class="display-4 mb-3 font-weight-bold">TOOOR</span> <br />FÜR <span class="yellow--text text-uppercase">{{ anzeigetafel.heim.name }}</span>!!!</h1>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4" align-self="center">
                        <v-avatar color="white" height="20vw" width="20vw">
                            <v-img :src="anzeigetafel.heim.logo"></v-img>
                        </v-avatar>
                    </v-col>
                    <v-col cols="4" align-self="center">
                        <v-row justify="center" align="center" no-gutters>
                            <h1 class="score font-weight-bold yellow--text">{{ anzeigetafel.tore_heim }}</h1>
                            <h1 class="score-goal white--text font-weight-bold">:</h1>
                            <h1 class="score-goal white--text font-weight-bold">{{ anzeigetafel.tore_gast }}</h1>
                        </v-row>
                        
                    </v-col>
                    <v-col cols="4" align-self="center">
                        <v-avatar color="white" height="20vw" width="20vw">
                            <v-img :src="anzeigetafel.gast.logo"></v-img>
                        </v-avatar>
                    </v-col>
                </v-row>
            </v-main>
        </v-slide-x-transition>
        <v-slide-x-reverse-transition leave-absolute>
            <v-main fluid dark v-if="anzeigetafel && anzeigetafel.screen == 'Tor_Gast'">
                <v-row justify="end" style="width: 100vw; position: absolute; top: 3vh; right: 2vw;">
                    <v-col cols="3" align-self="center">
                        <v-card class="rounded-xl pa-0" color="rgba(253,212,22,0.9)" light>
                            <keep-alive>
                                <v-row justify="center" class="text-center">
                                    <v-col cols="8" class="py-0" align-self="center">
                                        <h1 class="display-1 font-weight-bold text-uppercase vox-round-bold">Spielzeit</h1>
                                        <h1 class="display-4 font-weight-bold my-0">{{ timer.minuten.toString().padStart(2, '0') }}:{{ timer.sekunden.toString().padStart(2, '0') }}</h1>
                                    </v-col>  
                                    <v-col cols="4" align-self="center" class="pa-0">
                                        <v-card class="rounded-r-xl rounded-l-0" color="rgba(222,222,222,0.95)">
                                            <v-img contain src="@/assets/logos/arag_cup_logo_rgb.png"></v-img>
                                        </v-card>
                                    </v-col>      
                                </v-row>
                            </keep-alive>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify="center" class="text-center" style="height: 100vh;">
                    <v-col cols="12" align-self="center">
                        <v-row justify="center" style="padding-top: 5vh;">
                            <v-col cols="auto" align-self="center">
                                <h1 class="display-3 font-weight-bold"><span class="display-4 mb-3 font-weight-bold">TOOOR</span> <br />FÜR <span class="yellow--text text-uppercase">{{ anzeigetafel.gast.name }}</span>!!!</h1>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4" align-self="center">
                        <v-avatar color="white" height="20vw" width="20vw">
                            <v-img :src="anzeigetafel.heim.logo"></v-img>
                        </v-avatar>
                    </v-col>
                    <v-col cols="4" align-self="center">
                        <v-row justify="center" align="center" no-gutters>
                            <h1 class="score-goal white--text font-weight-bold">{{ anzeigetafel.tore_heim }}</h1>
                            <h1 class="score-goal white--text font-weight-bold">:</h1>
                            <h1 class="score font-weight-bold yellow--text">{{ anzeigetafel.tore_gast }}</h1>
                        </v-row>
                        
                    </v-col>
                    <v-col cols="4" align-self="center">
                        <v-avatar color="white" height="20vw" width="20vw">
                            <v-img :src="anzeigetafel.gast.logo"></v-img>
                        </v-avatar>
                    </v-col>
                </v-row>
            </v-main>
        </v-slide-x-reverse-transition>
        <v-slide-x-transition leave-absolute>
            <v-main fluid dark v-if="anzeigetafel && anzeigetafel.screen == 'Spiel'" style="height: 100vh; overflow: hidden;">
                <v-row justify="space-around" style="height: 20vh; padding-top: 5vh;">
                    <v-col cols="2" align-self="center">
                        <h1 class="vox-bold display-2 font-weight-bold mb-2 text-center white--text" v-if="anzeigetafel.zweiminheim.length > 0">{{ anzeigetafel.zweiminheim.length == 1 ? 'Zeitstrafe' : 'Zeitstrafen' }}</h1>
                        <v-row v-if="anzeigetafel.zweiminheim.length > 0" light>
                            <v-col cols="12" align-self="center" v-for="(item, index) in zwei_minuten_heim" :key="'2mingast'+index">
                                <v-card color="yellow" flat  class="rounded-xl pa-2 text-center" style="overflow: hidden;" v-if="item.show">
                                    <h1 class="display-3 font-weight-bold black--text" >{{ item.minuten.toString().padStart(2, '0') }}:{{ item.sekunden.toString().padStart(2, '0') }}</h1>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4" align-self="center">
                        <keep-alive>
                            <v-card class="rounded-xl pa-0" color="rgba(253,212,22,0.9)">
                                <v-row justify="center" class="text-center pa-0">
                                    <v-col cols="8" align-self="center">
                                        <h1 class="display-2 font-weight-bold text-uppercase vox-round-bold mb-2">Spielzeit</h1>
                                        <v-divider class="my-5"></v-divider>
                                        <h1 class="spielzeit font-weight-bold">{{ timer.minuten.toString().padStart(2, '0') }}:{{ timer.sekunden.toString().padStart(2, '0') }}</h1>
                                    </v-col>  
                                    <v-col cols="4" align-self="center" class="pa-0">
                                        <v-card class="rounded-r-xl rounded-l-0 pa-1 ma-0" color="rgba(222,222,222,1)" light>
                                            <v-img contain src="@/assets/logos/arag_cup_logo_rgb.png"></v-img>
                                        </v-card>
                                    </v-col>     
                                </v-row>
                            </v-card>
                        </keep-alive>
                    </v-col>
                    <v-col cols="2" align-self="center">
                        <h1 class="vox-bold text-center white--text" v-if="anzeigetafel.zweimingast.length > 0">{{ anzeigetafel.zweimingast.length == 1 ? 'Zeitstrafe' : 'Zeitstrafen' }}</h1>
                        <v-row v-if="anzeigetafel.zweimingast.length > 0" light style="height: 8vh;">
                            <v-col cols="12" align-self="center" v-for="(item, index) in zwei_minuten_gast" :key="'2mingast'+index">
                                <v-card color="yellow" flat  class="rounded-xl pa-4 text-center" style="overflow: hidden;" v-if="item.show">
                                    <h1 class="display-3 font-weight-bold black--text" >{{ item.minuten.toString().padStart(2, '0') }}:{{ item.sekunden.toString().padStart(2, '0') }}</h1>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="space-between" class="text-center" style="height: 50vh; padding-top: 5vh;">
                    <v-col cols="3" align-self="center">
                        <v-avatar color="white" height="20vw" width="20vw">
                            <v-img :src="anzeigetafel.heim.logo"></v-img>
                        </v-avatar>
                    </v-col>
                    <v-col cols="6" align-self="center">
                        <h1 class="score">{{ anzeigetafel.tore_heim }}:{{ anzeigetafel.tore_gast }}</h1>
                    </v-col>
                    <v-col cols="3" align-self="center">
                        <v-avatar color="white" height="20vw" width="20vw">
                            <v-img :src="anzeigetafel.gast.logo"></v-img>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-row justify="space-between" class="text-center text-uppercase" style="position: fixed; bottom: 10px; width: 100vw;">
                    <v-col cols="12" align-self="center">
                        <v-row justify="center" align="center" no-gutters class="mb-5">
                            <h1 class="display-2 font-weight-bold yellow--text" v-if="anzeigetafel.gruppe && anzeigetafel.gruppe.name">{{ anzeigetafel.runde.name }} - {{ anzeigetafel.gruppe.name }}</h1>
                            <h1 class="display-2 font-weight-bold yellow--text" v-else>{{ anzeigetafel.runde.name }}</h1>
                        </v-row>
                        <v-row justify="center" align="center" no-gutters class="my-5">
                            <h1 class="display-3 font-weight-bold white--text">{{ anzeigetafel.heim.name }}</h1>
                            <h1 class="font-weight-bold display-3 yellow--text mx-5 text-lowercase">vs</h1>
                            <h1 class="display-3 font-weight-bold white--text">{{ anzeigetafel.gast.name }}</h1>
                        </v-row>
                    </v-col>
                </v-row>
            </v-main>
        </v-slide-x-transition>
        
        <v-main fluid dark v-if="anzeigetafel && anzeigetafel.screen == 'Get_Ready'">
            <v-row justify="center" class="text-center" style="height: 100vh;">
                <v-col cols="8" class="text-center" align-self="center">
                    <h1 class="display-4 font-weight-bold text-center">
                        Macht euch bereit!
                    </h1>
                    <v-row justify="center" class="mt-5">
                        <v-col cols="10">
                            <v-card class="rounded-xl py-0 elevation-0" color="rgba(253,212,22,0)">
                                <v-row justify="center" class="text-center py-0">
                                    <v-col cols="8" align-self="center" class="text-center">
                                        <h1 class="score">{{ countdown.sekunden }}</h1>
                                    </v-col>  
                                    <v-col cols="4" align-self="center">
                                        <v-card class="rounded-xl pa-1" color="rgba(222,222,222,0.8)" light>
                                            <v-img contain src="@/assets/logos/arag_cup_logo_rgb.png"></v-img>
                                        </v-card>
                                    </v-col>     
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    
                </v-col>
            </v-row>
        </v-main>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { doc, getFirestore, onSnapshot } from '@firebase/firestore'

export default {
    name: 'anzeigetafel',
    data(){
        return {
            timer: {
                minuten: 0,
                sekunden: 0
            },
            countdown: {
                sekunden: 10,
            },
            anzeigetafelSubscribe: '',
            anzeigetafel: '',
            zwei_minuten_heim: [
                {
                    show: false,
                    minuten: 2,
                    sekunden: 0,
                },
                {
                    show: false,
                    minuten: 2,
                    sekunden: 0,
                },
                {
                    show: false,
                    minuten: 2,
                    sekunden: 0,
                },
            ],
            zwei_minuten_gast: [
                {
                    show: false,
                    minuten: 2,
                    sekunden: 0,
                },
                {
                    show: false,
                    minuten: 2,
                    sekunden: 0,
                },
                {
                    show: false,
                    minuten: 2,
                    sekunden: 0,
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            turnier: 'turnier',
        }),
        spiel(){
            return this.turnier.get_aktuelles_spiel()
        },
        torschuetze(){
            return this.anzeigetafel.letzter_torschuetze
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init(){
            if(this.turnier?.name){
                const anzeigetafelDoc = doc(getFirestore(), 'Turniere/'+this.turnier.id+'/Anzeigetafel/1')
                this.anzeigetafelSubscribe = onSnapshot(anzeigetafelDoc, snap => {
                    console.log(snap)
                    if(snap.exists()){
                        this.anzeigetafel = snap.data()
                        console.log(this.anzeigetafel)
                        this.start_spielzeit()

                        this.zwei_minuten_heim.forEach((item, index) => {
                            if(index < this.anzeigetafel.zweiminheim.length){
                                item.show = true
                            }
                            else {
                                item.show = false
                            }
                        })
                        this.zwei_minuten_gast.forEach((item, index) => {
                            if(index < this.anzeigetafel.zweimingast.length){
                                item.show = true
                            }
                            else {
                                item.show = false
                            }
                        })

                        //FORCE SCREEN
                        //this.anzeigetafel.screen = 'Next'
                    }
                    else {
                        console.log('No Doc for '+this.turnier.id)
                    }
                })
            }
            else {
                console.log('init')
                setTimeout(() => {
                    this.init()
                }, 100)
            }
        },
        start_spielzeit(){
            const spiel = this.anzeigetafel
            
            if(spiel && spiel.status == 'gestartet' && spiel.spielzeit.status == 'gestartet'){
                const spielzeit = Object.assign({}, spiel.spielzeit)
                const now = Date.now()

                const spielzeit_seconds = (spielzeit.verbleibend.minuten * 60) + spielzeit.verbleibend.sekunden 

                const seconds = parseInt((now - spielzeit.verbleibend.timestamp) / 1000)

                this.timer.sekunden = parseInt(spielzeit_seconds - seconds) % 60
                this.timer.minuten = parseInt((spielzeit_seconds - seconds) / 60)

                if(this.timer.sekunden < 0){
                    this.timer.sekunden = 0
                }
                if(this.timer.minuten < 0){
                    this.timer.minuten = 0
                }

                if(spiel.zweiminheim){
                    spiel.zweiminheim.forEach((item, index) => {
                        const zweimin_seconds = (item.verbleibend.minuten * 60) + item.verbleibend.sekunden 
                        const seconds = parseInt((now - item.timestamp) / 1000)
                        const verbleibend = parseInt(zweimin_seconds - seconds)

                        if(verbleibend > 0){
                            this.zwei_minuten_heim[index].show = true
                            this.zwei_minuten_heim[index].sekunden = verbleibend % 60
                            this.zwei_minuten_heim[index].minuten = parseInt(verbleibend / 60)
                        }
                        else {
                            this.zwei_minuten_heim[index].show = false
                            this.zwei_minuten_heim[index].sekunden = 2
                            this.zwei_minuten_heim[index].minuten = 0
                        }
                    })
                }
                if(spiel.zweimingast){
                    spiel.zweimingast.forEach((item, index) => {
                        const zweimin_seconds = (item.verbleibend.minuten * 60) + item.verbleibend.sekunden 
                        const seconds = parseInt((now - item.timestamp) / 1000)
                        const verbleibend = parseInt(zweimin_seconds - seconds)

                        if(verbleibend > 0){
                            this.zwei_minuten_gast[index].show = true
                            this.zwei_minuten_gast[index].sekunden = verbleibend % 60
                            this.zwei_minuten_gast[index].minuten = parseInt(verbleibend / 60)
                        }
                        else {
                            this.zwei_minuten_gast[index].show = false
                            this.zwei_minuten_gast[index].sekunden = 2
                            this.zwei_minuten_gast[index].minuten = 0
                        }
                    })
                }
            }
            else if(spiel && spiel.screen == 'Get_Ready'){
                const spielzeit = Object.assign({}, spiel.countdown)
                const now = Date.now()

                const spielzeit_seconds = spielzeit.sekunden 
                const seconds = parseInt((now - spielzeit.timestamp) / 1000)

                this.countdown.sekunden = parseInt(spielzeit_seconds - seconds) <= 0 ? 0 : parseInt(spielzeit_seconds - seconds)
            }
            else if(spiel && (spiel.spielzeit.status == 'pausiert' || spiel.status == 'geplant')){
                this.timer.minuten = spiel.spielzeit.verbleibend.minuten
                this.timer.sekunden = spiel.spielzeit.verbleibend.sekunden
            }
            
            setTimeout(() => {
                this.start_spielzeit()
            }, 1000)
        },
    }
}
</script>

<style>
    .v-application {
        color: #fff !important;
    }

    .v-application .display-4 {
        font-family: "vox-round", sans-serif;
    }

    .v-application .display-3 {
        font-family: "vox-round", sans-serif !important;
    }
    .v-application .display-2 {
        font-family: "vox-round", sans-serif !important;
    }
    .v-application .display-1 {
        font-family: "vox-round", sans-serif !important;
    }

    .score {
        font-size: 26rem !important;
        font-family: "vox-round", sans-serif !important;
        color: #fff;
    }
    
    .countdown {
        font-size: 20rem !important;
        font-family: "vox-round", sans-serif !important;
        color: #fff;
    }

    .goal {
        font-size: 8rem !important;
        line-height: 8rem;
        font-family: "vox-round", sans-serif !important;
        color: #fff;
    }
    .goal-team {
        font-size: 5rem !important;
        line-height: 5rem;
        font-family: "vox-round", sans-serif !important;
        color: #fff;
    }

    .torschuetze {
        font-size: 9rem !important;
        line-height: 10rem;
        font-family: "vox-round", sans-serif !important;
        color: #fff;
    }
    
    .torschuetze-tore {
        font-size: 7rem !important;
        line-height: 7rem;
        font-family: "vox-round", sans-serif !important;
        font-style: italic;
        color: #fff;
    }

    .score-goal {
        font-size: 14rem !important;
        font-family: "vox-round", sans-serif !important;
        color: #fff;
    }
    .foulscore {
        font-size: 12rem !important;
        font-family: "vox-round", sans-serif !important;
        font-weight: 700 !important;
        line-height: 12rem;
        color: #fff;
    }
    .spielzeit {
        font-size: 8rem !important;
        line-height: 9rem;
        font-family: "vox", sans-serif !important;
    }

    
</style>